import React, {Component} from 'react';
import translate from "../../../translations/translations.wrapper.jsx";
import WorkflowDetailsWizardStep
    from "components/taskdetails-remake/panels/workflowdetails/WorkflowDetailsWizardStep.component";

class WorkflowDetailsWizard extends Component {

    renderSteps() {
        return this.props.data.process.steps.filter((step) => {
            return this.props.hideSkippedSteps ? step.status !== "SKIPPED" : true
        }).map((step) => {
            return (
                <WorkflowDetailsWizardStep key={step.stepNumber}
                                           step={step}
                                           data={this.props.data}
                                           displaySourceApplication={this.props.data.displaySourceApplication}>
                    {step.name}
                </WorkflowDetailsWizardStep>
            )
        })
    }

    render() {
        return (
            <div className="workflow-wizard">
                <WorkflowDetailsWizardStep approvalInitiated={true}
                                           key={"-1"}
                                           data={this.props.data}>
                    {this.props.translate("taskDetail.approvalInitiated")}
                </WorkflowDetailsWizardStep>
                {this.renderSteps()}
            </div>
        );
    }
}

const withTranslations = translate(WorkflowDetailsWizard);
export default withTranslations;
